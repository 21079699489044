<template>
  <div>
    <h3>{{ user.name }}</h3>
    <div class="data">
      <strong>E-mail: </strong>
      <a style="color: #2c3e50" :href="'mailto:' + user.email">{{
        user.email
      }}</a>
    </div>
    <div class="data">
      <strong>Phone: </strong>
      <a style="color: #2c3e50" :href="'tel:' + user.phone">{{ user.phone }}</a>
    </div>
    <div class="data"><strong>City: </strong> {{ user.city }}</div>
    <div class="data"><strong>Languages: </strong> {{ user.lang }}</div>
    <SocialBar class="data" :links="links" />
  </div>
</template>

<script>
import SocialBar from "./SocialBar.vue";

export default {
  name: "PersonnalCard",
  props: ["user", "links"],
  components: {
    SocialBar,
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/constants.scss";

.data {
  margin-bottom: 10px;
}
</style>