export default {
    "user": {
        "city": "Çankaya, Ankara",
        "email": "info@celalettindemir.dev",
        "lang": "Turkish, English",
        "name": "Celalettin Demir",
        "phone": "+90 553 861 4696",
        "photo": "photo.jpg",
        "status": "Full-Stack Developer",
    },
    "projects":
    {
        "description": "This is my story",
        "title": "My projects",
        "items": [

            {
                "title": "ARCLOUD",
                "content": `Arcloud is a system for monitoring machines. It processes the instant status of the machines, post-welding report, maintenance timing data. It makes inferences according to the rule data that comes to the welding machine over the system. This communication is carried out on mqtt. Custom drag and drop dashboard, machine components and operation history, welds charts, setting machine memory provides services<br><br>
                Technology : TypeScript, React, React Query, .Net Core Services, Golang, Postgresql Time Series DB, Redis, Mongo, Chackra UI, EMQX, Kafka, Kafka Connect, React Grid Layout, Kong, ApexChart<br><br>
                Link : <a href="https://arcloud.celalettindemir.dev/">Arclud</a>`,
                "image": "arcloudproject.gif",
                "tag": "IOT",
                "color": "yellow",
                "date": "2022-09-10"
            },
            {
                "title": "OPTIMIZER MONITOR SYSTEM",
                "content": `It is an iot application used for panel monitoring of solar inverter. The aim is to monitor panel efficiency. The statuses of the instant panels are examined on the Dashboard.<br><br>
                Technology : React, React Query, TypeScript, ApexChart, .Net Core API, Postgresql Time Series DB, Chackra UI, EMQX, Kafka, Kafka Connect<br><br>
                Link : <a href="https://solar.celalettindemir.dev/">Optimizer Monitor</a>`,
                "image": "solarproject.gif",
                "tag": "IOT",
                "color": "yellow",
                "date": "2022-11-05"
            },
            {
                "title": "Quiz Module",
                "content": `The quiz module is written externally for education portal.<br/><br/>
                    Technology : Nodejs, MongoDB, Vuejs, Express`,
                "image": "quizmodule.gif",
                "tag": "Web",
                "color": "blue",
                "date": "2022-01-20"
            },

            {
                "title": "Inverter Panel Compatibility",
                "content": `Panel and Inverter Compatibility<br/><br/>
                    Technology : Vuejs, Apexcharts, In Memory Db`,
                "image": "invpanelcalc.gif",
                "tag": "Web",
                "color": "blue",
                "date": "2021-08-10"
            },
            {
                "title": "Education Portal",
                "content": `It is the portal of an institution for KPSS education. It was later integrated into the system. It works integrated with BBB and Zoom.<br/><br/>
                    Technology : C# .Net Core, Layered Pattern, JQuery, Mysql (Zoom, BBB integration)<br/><br/>
                    Link : <a href='https://maratonacademy.com/'>Maraton Academy</a>`,
                "image": "maratonacademy.gif",
                "tag": "Web",
                "color": "blue",
                "date": "2021-02-10"
            },
            {
                "title": "Epos – Electronic restaurant automation system",
                "content": `A software that provides communication within the
                    restaurant in England. It serves as Desktop, Web, API,
                    Mobile App.<br><br>
                    Technology : .Net core API, C# windows form, Flutter, MsSql
                    `,
                "image": "",
                "tag": "Desktop",
                "color": "purple",
                "date": "2020-10-28"
            },
            {
                "title": "Esystem",
                "content": `It is a system that reports how much the areas
                    cleaned with cleaning mops have been cleaned. It is
                    demo work. <br><br>
                    Technology : Beacon, React, Nodejs, ESP32, IMU, Indoor Location Detection, MongoDB`,
                "image": "",
                "tag": "IOT",
                "color": "yellow",
                "date": "2020-05-08"
            },
            {
                "title": "Çayyolla",
                "content": `A software written to manage the communication
                    between shops and tea sellers.<br><br>
                    Link : <a href='https://cayyolla.com/'>App WebSite</a>, <a href='https://play.google.com/store/apps/details?id=com.hineks.cayyolla&hl=tr&gl=US'>Play Store</a> <br><br>
                    Technology : React Native, .Net Core API, MsSql`,
                "link": "https://cayyolla.com/",
                "image": "cayyolla.png",
                "tag": "Mobile",
                "color": "green",
                "date": "2019-10-17"
            },
            {
                "title": "Electrical Project Tracking Automation",
                "content": `The software that follows the process from the
                    drawing of the electrical installation project to the
                    installation. Informing the electricians that the
                    institution works with by SMS. It is a desktop
                    application.<br/><br/>
                    Technology : Spring Boot, JavaFX,Spring Scheduled, Postgresql <br/> <br/>
                    Link : <a href='https://github.com/celalettindemir/Project-Tracking-Automation'>Project tracking system</a>`,
                "image": "electrical_project_tracking_automation.gif",
                "tag": "Desktop",
                "color": "purple",
                "date": "2019-05-19"
            },
            {
                "title": "Zaro",
                "content": `Dice game<br/><br/>
                    Technology : C#, Unity <br/> <br/>
                    Link : <a href='https://github.com/celalettindemir/Project-Tracking-Automation'>Project tracking system</a>`,
                "image": "zaro.gif",
                "tag": "Desktop",
                "color": "purple",
                "date": "2019-05-19"
            }]
    },
    "description": {
        "description": "",
        "pres_first": "I'm a Full-Stack Developer.",
        "pres_second": "",
        "pres_title": "Who am I ?",
        "title": "About Me"
    },
    "links": {
        "facebook": "https://www.facebook.com/celal258",
        "instagram": "https://www.instagram.com/celal258",
        "linkedin": "https://www.linkedin.com/in/celalettindemir",
        "github": "https://github.com/celalettindemir",
        "hackerrank": "https://www.hackerrank.com/celalettindemir",
        "twitter": "https://twitter.com/celalettindemir"
    },
    "skills": {
        "items": [
            {
                "title": "C#",
                "img": "Csharp.png"
            },
            {
                "title": ".Net",
                "img": "dot-net.svg"
            },
            {
                "title": "Nodejs",
                "img": "nodejs.svg"
            },
            {
                "title": "Express",
                "img": "express.svg"
            },
            {
                "title": "Go",
                "img": "go.png"
            },
            {
                "title": "React",
                "img": "react.png"
            },
            {
                "title": "React Query",
                "img": "react-query.svg"
            },
            {
                "title": "Apexcharts",
                "img": "apexcharts.svg"
            },
            {
                "title": "Linux",
                "img": "linux.png"
            },
            {
                "title": "Gitlab CI/CD",
                "img": "gitlab-ci.png"
            },
            {
                "title": "Kong API Gateway",
                "img": "kong-gateway.png"
            },
            {
                "title": "Puppeteer",
                "img": "puppeteer.svg"
            },
            {
                "title": "Kafka",
                "img": "apachekafka.png"
            },
            {
                "title": "Jest",
                "img": "jest.png"
            },
            {
                "title": "Docker",
                "img": "docker.svg"
            },
            {
                "title": "Git/Github",
                "img": "Octocat.png"
            },
            {
                "title": "Google Cloud",
                "img": "gcloud.png"
            },
            {
                "title": "React Native",
                "img": "react-native.svg"
            },
            {
                "title": "Redis",
                "img": "redis.svg"
            },
            {
                "title": "MongoDb",
                "img": "mongodb.svg"
            },
            {
                "title": "Mysql",
                "img": "mysql.svg"
            },
            {
                "title": "PostgreSql",
                "img": "postgresql.svg"
            },
            {
                "title": "C",
                "img": "C.png"
            },
            {
                "title": "C++",
                "img": "cplusplus.png"
            },
            {
                "title": "Espressif",
                "img": "espressif.png"
            }
        ],
        "title": "Skills",
        "description": "Here is my armament"
    },
    "experiences": {
        "description": "Professional and academic",
        "title": "Experience",
        "Career": [
            {
                "year": "05.2020 - 03.2022",
                "title": "Kolarc",
                "content": `I worked on sending the data of welding machines
                to the internet and examining them with rules such
                as welding accuracy (WPS). I was the only authority
                on the IT side. While working here, I communicated
                machines using communication methods such as
                Modbus TCP/RTU, UART, MQTT, TcpSocket, HTTP. I
                wrote API service with Nodejs running in the cloud
                and interface with Vuejs. I have written an android
                mobile application with Java that configures the
                welding machines`
            },
            {
                "year": "08.2019 - 09.2019",
                "title": "Internship – Bestcloudfor.me",
                "content": `I had the opportunity to use Devops technologies,
                Docker for Virtualization, Grafana for Monitoring
                and Kubernates for cluster management and gain
                experience in this field.`
            },
            {
                "year": "since 04.2019",
                "title": "Hineks",
                "content": `I took part in all project development processes at
                Hineks, where I was the founding partner. I took
                part in analysis, design, MVP, product and testing
                stages. I developed myself as a fullstack developer
                with React Native and Flutter in the mobile field,
                with React, WPF, JavaFX, C#, Java, .Net core, .net
                Web API and Node Js.`
            },
            {
                "year": "06.2016 - 05.2018",
                "title": "Exert",
                "content": `I work as a web developer,
                I took part in the stages of converting the sites
                written in webform to asp.net and applying the mvc
                pattern. Within the scope of these developments, I
                developed e-commerce portals and user-interactive
                sites`
            }
        ],
        "Training": [
            {
                "year": "From March 2022",
                "title": "Modanisa Fullstack Bootcamp",
                "content": "Training by Patika and Modanisa"
            },
            {
                "year": "2017 - 2021",
                "title": "Bachelor of Electronic Engineering",
                "content": "Sakarya University, Turkey"
            },
            {
                "year": "2015 - 2019",
                "title": "Bachelor of Computer Engineering",
                "content": "Sakarya University, Turkey"
            }
        ],


    }
}