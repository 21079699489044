<template>
  <section id="about">
    <div data-aos="fade-down">
      <Title :title="content.title" :description="content.description" />
    </div>

    <div data-aos="fade-right">
      <div class="section-content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-6 mr-auto card-mobile">
              <Presentation :content="content" />
            </div>
            <div class="col-md-12 col-lg-12 col-xl-5 card-mobile row">
              <div class="col-md-5">
                <Photo :user="user" />
              </div>
              <div class="col-md-7">
                <PersonnalCard :user="user" :links="links" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Title from "./Title.vue";
import PersonnalCard from "./PersonnalCard.vue";
import Presentation from "./Presentation.vue";
import Photo from "./Photo.vue";

export default {
  name: "AboutMe",
  props: ["user", "content", "links"],
  components: {
    Title,
    PersonnalCard,
    Presentation,
    Photo,
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/constants.scss";

#about {
  background-color: lighten(map-get($colors, dark), 100%);
}

@media (min-width: #{map-get($breakpoints, medium)}) {
  .section-content {
    width: 80%;
    margin: 0 auto;
  }
}

@media (max-width: #{map-get($breakpoints, large)}) {
  .card-mobile {
    display: flex;
    justify-content: center;
    text-align: center !important;
    margin-top: 20px;
  }
}
</style>