 <template>
  <div id="social" style="margin-top: 10px">
    <p align="left" dir="auto">
      <a :href="links.linkedin" rel="nofollow"
        ><img
          align="center"
          src="https://raw.githubusercontent.com/rahuldkjain/github-profile-readme-generator/master/src/images/icons/Social/linked-in-alt.svg"
          alt="celalettindemir"
          height="30"
          width="40"
          style="max-width: 100%"
      /></a>
      <a :href="links.hackerrank" rel="nofollow"
        ><img
          align="center"
          src="https://raw.githubusercontent.com/rahuldkjain/github-profile-readme-generator/master/src/images/icons/Social/hackerrank.svg"
          alt="celalettindemir"
          height="30"
          width="40"
          style="max-width: 100%"
      /></a>
      <a :href="links.github" rel="nofollow"
        ><img
          align="center"
          src="https://raw.githubusercontent.com/rahuldkjain/github-profile-readme-generator/master/src/images/icons/Social/github.svg"
          alt="celalettindemir"
          height="30"
          width="40"
          style="max-width: 100%"
      /></a>
    </p>
  </div>
</template>

<script>
export default {
  name: "SocialBar",
  props: ["links"],
};
</script>

<style scoped lang="scss">
@import "@/styles/constants.scss";
.social-wrap {
  li {
    display: inline-block;
    margin-right: 10px;
  }
  .icon {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 1200px) {
  #social {
    display: flex;
    justify-content: center;
  }
}
</style>