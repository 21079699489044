import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue';
import App from './App.vue'
import AOS from 'aos'
import 'aos/dist/aos.css'


Vue.use(BootstrapVue)

import VueTimeline from "@growthbunker/vuetimeline";

Vue.use(VueTimeline);
Vue.config.productionTip = false

new Vue({
  created() {
    AOS.init()
  },
  render: h => h(App),
}).$mount('#app')

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './styles/global.scss'
