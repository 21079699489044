<template>
  <div name="fade" tag="div" class="wrapper" mode="out-in">
    <div class="wrapper" v-if="isLoaded" id="app">
      <LandingPage :user="user" />
      <Description :user="user" :content="description" :links="links" />
      <Experience :content="experiences" />
      <Skills :content="skills" />
      <Projects :content="projects" />
      <Footer :user="user" :links="links" />
    </div>
  </div>
</template>

<script>
import LandingPage from "./components/LandingPage.vue";
import Description from "./components/Description.vue";
import Experience from "./components/Experience.vue";
import Skills from "./components/Skills.vue";
import Projects from "./components/Projects.vue";
import Footer from "./components/Footer.vue";
import Content from "./content";

export default {
  name: "App",
  components: {
    LandingPage,
    Description,
    Experience,
    Skills,
    Projects,
    Footer,
  },
  data: () => ({
    isLoaded: false,
    user: Content.user,
    description: Content.description,
    links: Content.links,
    experiences: Content.experiences,
    skills: Content.skills,
    projects: Content.projects,
    posts: [],
  }),
  methods: {},
  created() {
    document.body.classList.add("loading");
    this.isLoaded = true;
    this.$nextTick(() => document.body.classList.remove("loading"));
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/constants.scss";

#app {
  font-family: Montserrat-Regular, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.wrapper {
  height: 100%;
}
</style>
