<template>
  <div class="paragraph">
    <h3>Who am I ?</h3>
    <div class="begin">
      I am a enthusiastic software developer with more than 2 years of
      experience. I like to build cool stuff.I make sure things are done right,
      I follow directions.
    </div>
    <p>
      I'm highly focused on application performance, usability, maintainability,
      portability, and scalability. I strictly follow the Object Oriented
      Programming paradigm and use Design Patterns, where appropriate. I am very
      passionate about what I do and it shows in my work.
    </p>
    <p>
      I work under a test-driven development (having worked with jest and
      puppeteer test libraries). I work to maintain dynamic, scalable, optimal,
      and high-performance code.
    </p>
  </div>
</template>

<script>
export default {
  name: "Description",
  props: ["content"],
};
</script>

<style scoped lang="scss">
@import "@/styles/constants.scss";

.paragraph {
  color: map-get($colors, primary);
  .begin {
    color: map-get($colors, secondary);
  }
}
</style>